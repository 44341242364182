import React from 'react';

function DSSHeader ( { step, question } ) {
    return (
        <nav className="container p-1 dss-header">
            <div className='columns is-vcentered'>
                <div className='column is-12'>
                    { step === 0 && <div className='is-flex is-align-items-center is-justify-content-left'>
                        <img className='header-logo' src='icon512.png' alt='Logo' />
                        <h1 className='title is-3 has-text-primary ml-3'>Nakupovalni pomočnik</h1>                        
                    </div>
                    }
                    { step !== 0 && <div className='column'>        
                            <div className='title is-3 has-text-primary'>{question.main}</div>
                            <div className='subtitle is-5 has-text-black mt-1'>{question.shortExplanation}</div>
                        </div>
                    }
                </div>
            </div>            
        </nav>
    )
}

export default DSSHeader;