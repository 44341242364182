import React from 'react';

function DSSResults ( { results } ) {
    return (
        <div className="content">
            <h2 className="title is-5 has-text-primary">
                <span className='mr-1'>Priporočeni izdelki:</span>
                {/*<span className="tag is-rounded is-normal is-dark"> {results.length}</span>*/}
            </h2>
            {/*
            <pre>{JSON.stringify(results, null, 2)}</pre>
            */}
            {results.map((item, index) => (
            <div key={index} className="media">
                <div className="media-content">
                <p>
                    <span className=''>{index+1} - </span>
                    <span>{item['name']}</span>
                </p>
                </div>
                <div className="media-right">
                <a href={item.url} target="_blank" rel="noopener noreferrer" className="icon">
                    <i className="fas fa-link"></i>
                </a>
                </div>
            </div>
            ))}
        </div>
    )
}

export default DSSResults;