import React from 'react';

function DSSFilter({ results, selected, clearSelected }) {    

    return (
        <div className="is-size-6 is-gray">Število priporočil: 
        {/*<span className='tag is-white ml-1 is-light is-rounded has-text-black'>{results.length} </span>*/}
        <span className=''> {results.length}</span>
        
            <div className="tags is-centered">
                {Object.keys(selected).map((key) => (
                    selected[key].map((value, idx) => (
                        <div key={idx} className="tag is-light is-lowercase	has-text-black">
                            {value}
                        </div>
                    ))                
                ))}
                {!Object.values(selected).every((array) => array.length === 0) && (
                    <button className="button is-light is-rounded is-small has-black-border"
                        onClick={() => {
                            clearSelected();                            
                        }}
                    >
                        <span className="icon is-small">
                            <i className="fas fa-times"></i>
                        </span>                        
                    </button>
                )}
            </div>
        </div>
    );
}

export default DSSFilter;