import './App.css';
import DSSHeader from './DSSHeader';
import DSSFooter from './DSSFooter';
import { useState, useEffect } from 'react';
import { readExcelFile, getUniqueValues, getQuestions } from './dataProvider';
import DSSResults from './DSSResults';
import DSSInput from './DSSInput';

function App() {
  
  const [currentStep, setCurrentStep] = useState(0);
  
  let questions = getQuestions();

  const [data, setData] = useState([]);
  const [uniqueValues, setUniqueValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const filePath = `${process.env.PUBLIC_URL}/dss_data.xlsx`;
      const jsonData = await readExcelFile(filePath);
      setData(jsonData);      

      const columns = Object.keys(jsonData[0]);
      const uniqueValuesObj = {};
      columns.forEach(column => {
        uniqueValuesObj[column] = getUniqueValues(jsonData, column);
      });      
      setUniqueValues(uniqueValuesObj);

      // Initialize selectedValues with empty arrays for each column
      const initialSelectedValues = {};
      columns.forEach(column => {
        initialSelectedValues[column] = [];
      });
      setSelectedValues(initialSelectedValues);      
    };

    fetchData();
  }, []);

  const handleButtonClick = (column, value) => {        
    setSelectedValues(prevSelectedValues => {
      const columnValues = prevSelectedValues[column] || [];
      if (columnValues.includes(value)) {
        const newSelectedValues = {
          ...prevSelectedValues,
          [column]: columnValues.filter(item => item !== value)
        };        
        return newSelectedValues;
      } else {
        const newSelectedValues = {
          ...prevSelectedValues,
          [column]: [...columnValues, value]
        };        
        return newSelectedValues
      }
    });    
  };

  const filterData = () => {    
    return data.filter(item => {
      return Object.keys(selectedValues).every(column => {        
        if (selectedValues[column].length === 0) {          
          return true;
        }
        const itemValues = item[column].split(',').map(value => value.trim());        
        return selectedValues[column].some(selectedValue => itemValues.includes(selectedValue));
      });
    });
  };

  const filteredData = filterData();
  
  const handleStepChange = (increment) => {
      setCurrentStep(currentStep + increment <= questions.length ? currentStep + increment : 0);
  }

  const clearSelected = () => {    
    let newSelectedValues = {...selectedValues};    
    Object.keys(newSelectedValues).forEach((key) => {
        newSelectedValues[key] = [];
    });
    setSelectedValues(newSelectedValues);
  }

  return (
    <div className="main">
      <DSSHeader 
        step={currentStep}
        question={questions[currentStep-1]}
      />

      <section className="section py-1">        
          <div className="columns is-vcentered">
            <div className="column is-fullwidht">
            {
              currentStep === 0 && <>
              <p className="title my-1 has-text-link">Dobrodošli!</p>
              <p className="subtitle has-text-black">Z nekaj vprašanji vas popeljemo do boljše nakupne odločitve</p>
              </>
            }
            <div>
              {
                currentStep !== 0 && <>
                  <DSSInput
                    criteria = {questions[currentStep-1].category}
                    values = {uniqueValues}
                    selected = {selectedValues}
                    onClick = {handleButtonClick}
                    data={filteredData}
                  />                  
                  <DSSResults results={filteredData} />
                </>
              }
            </div>
            {
              currentStep === 0 && <button
                className="button is-primary has-text-white is-large is-rounded"
                onClick={() => handleStepChange(1)}
                >              
                  Začnimo
              </button>
            }
            </div>
          </div>        
      </section>

      <DSSFooter
        step={currentStep}
        handleStepChange={handleStepChange}
        question={questions[currentStep-1]}
        selected = {selectedValues}
        clearSelected = {clearSelected}
        results={filteredData} />
    </div>
  );
}

export default App;