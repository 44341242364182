import React from 'react';

function DSSInput ( { values, onClick, selected, criteria, data } ) {    
    
    const resultsAfterFiltering = (column, value) => {
        const selectedTested = structuredClone(selected);
        const dataTested = structuredClone(data);        
        selectedTested[column].push(value);        
        return dataTested.filter(item => {
            return Object.keys(selectedTested).every(column => {
                if (selectedTested[column].length === 0) {
                    return true;
                } else {
                    const itemValues = item[column].split(',').map(value => value.trim());
                    return selectedTested[column].some(selectedValue => itemValues.includes(selectedValue));
                }
            });
        }).length;
    };

    const eligibleFilter = (column, value) => {
        return data.some(item => {
            const itemValues = item[column].split(',').map(val => val.trim());
            return itemValues.includes(value);
        });
    };
    
    return (
        <div className="content">
            {Object.keys(values).map(column => (                
                column === criteria && (
                    <div key={column}>                        
                        <div className="buttons">
                            {values[column].map((value, index) => {                                
                                if (resultsAfterFiltering(column, value) !== 0 && eligibleFilter(column, value)) {                                   
                                    return (
                                        <button
                                            key={index}                      
                                            className={`button is-normal is-multiline is-lowercase is-primary has-text-black ${selected[column]?.includes(value) ? '' : 'is-outlined'}`}
                                            onClick={() => onClick(column, value)}
                                        >
                                            {value}
                                            <span className="tag is-white ml-1 is-light is-rounded">{resultsAfterFiltering(column, value)}</span>
                                        </button>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                )
                ))}
        </div>
    )
}

export default DSSInput;