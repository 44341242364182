import * as XLSX from 'xlsx';
import _ from 'lodash';

export const readExcelFile = async (filePath) => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

  return jsonData;
};

export const getUniqueValues = (data, column) => {
  let values;
  if (column === 'name') {    
    values = data.map(row => row[column]);
  } else {    
    values = data.map(row => row[column]).join(',').split(',');
  }
  return _.uniq(values.map(value => value.trim()).filter(value => value));
};

export const getQuestions = () => {
  return [
    {
      category: "plant_group",
      main: "Za katere skupine rastlin iščete gnojilo?",
      shortExplanation: "Če želite, izberite skupino rastlin, za katero potrebujete gnojilo. Za univerzalno gnojilo lahko izberete več skupin.",
      longExplanation: "Če iščete gnojilo za določeno skupino rastlin, npr. vrtnine, izberite ustrezno skupino. V naslednjem koraku boste lahko izbrali specifične rastline znotraj te skupine. Če pa iščete univerzalno gnojilo, lahko izberete več skupin rastlin, ki jih gojite na vašem vrtu."
    },
    {
      category: "plant_specific",
      main: "Za katere specifične rastline iščete gnojilo?",
      shortExplanation: "Izberite specifične rastline znotraj izbrane skupine, za katere potrebujete gnojilo ali pa nadaljujte na naslednje vprašanje.",
      longExplanation: "Če iščete gnojilo za specifično rastlino, npr. paradižnik, izberite to rastlino znotraj skupine vrtnine. Če ste v prejšnjem koraku izbrali več skupin, lahko zdaj izberete specifične rastline znotraj teh skupin. To vam bo pomagalo najti najbolj primerno gnojilo za vaše rastline."
    },
    {
      category: "fertilizer_type",
      main: 'Katero vrsto gnojila iščete?',
      shortExplanation: 'Organska gnojila so naravnega izvora in počasneje delujoča. Mineralna gnojila so industrijsko proizvedena in hitreje delujoča.',
      longExplanation: 'Organska gnojila so pridobljena iz naravnih virov, kot so kompost, gnoj in rastlinski ostanki. Ta gnojila poleg zalaganja rastlin s hranili še izboljšujejo strukturo tal, povečujejo njihovo sposobnost zadrževanja vode in spodbujajo mikrobiološko aktivnost v tleh. Mineralna gnojila so industrijsko proizvedena in vsebujejo specifične koncentracije hranil, kot so dušik, fosfor in kalij. Ta gnojila zagotavljajo hitro razpoložljivost hranil, kar omogoča takojšnjo oskrbo rastlin. So enostavna za uporabo in natančno odmerjanje, vendar ne izboljšujejo strukture tal na dolgi rok.'
    },
    {
      category: "specific_effect",
      main: 'Kakšen specifičen učinek želite doseči z gnojilom?',
      shortExplanation: 'Če želite, izberite specifičen cilj, ki ga želite doseči z gnojilom za vaše rastline.',
      longExplanation: 'Različna gnojila imajo različne učinke na rastline in tla. Na primer, nekatera gnojila zmanjšujejo fitotoksičnost, kar pomaga zmanjšati škodljive učinke prekomerne uporabe gnojil. Druga gnojila povečujejo zalogo fosforja v tleh, kar je ključno za razvoj koreninskega sistema in cvetenje. Določena gnojila povečujejo zalogo dušika, ki je bistvenega pomena za rast listov in stebel.'
    }
  ]
};
