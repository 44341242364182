import React from 'react';
import DSSFilter from './DSSFilter';

function DSSFooter({ step, handleStepChange, question, results, selected, clearSelected }) {
   
    const [showModal, setShowModal] = React.useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            {showModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <div className="box has-background-white has-text-black py-6">
                            <div className='level'>
                                {question.longExplanation}
                            </div>
                            <div className="level">
                                <button
                                    className="button is-primary has-text-white is-rounded mx-3"
                                    onClick={() => toggleModal()}>
                                    <span className="icon is-large">
                                        <i className="fas fa-info-circle"></i>
                                    </span>
                                    <span>Zapri</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        className="modal-close is-large"
                        aria-label="close"
                        onClick={toggleModal}
                    ></button>
                </div>
            )}
            <footer className="dss-footer py-3">
                <div className='container'>
                    <div className="columns is-vcentered">
                        <div className="column is-12 has-text-centered">
                            {step === 0 && (
                                <a href="https://partner.posadi.si/info/content/1/1">                                
                                    <p className="">
                                        <span>Prenesi Posadi.si - brezplačno<br/></span>{" "}
                                        <span>aplikacijo za vrtne navdušence 👇</span>
                                    </p>
                                    <img className="footer-badge" src="/google-play-badge-si.png" alt=""/>
                                </a>
                            )}
                            {step !== 0 && (                         
                                <>
                                    <DSSFilter                    
                                        selected = { selected }
                                        results = { results }
                                        clearSelected= { clearSelected }
                                    />
                                    <div className="buttons is-centered my-3">
                                    <button 
                                        className="button is-black is-circle mr-1"
                                        onClick={() => handleStepChange(-1)}
                                        >
                                        <span className="icon is-large">
                                            <i className="fas fa-arrow-left"></i>
                                        </span>
                                    </button>
                                    {/*<button
                                        className="button is-white is-circle"
                                        onClick={() => toggleModal()}>
                                        <span className="icon is-large">
                                            <i className="fas fa-info-circle"></i>
                                        </span>
                                    </button>*/}
                                    <button
                                        className="button is-white is-circle has-black-border"
                                        onClick={() => toggleModal()}>
                                        <span className="icon i-button">
                                            <i className="fas fa-info-circle"></i>
                                        </span>                                        
                                    </button>
                                    <button
                                        className="button is-black is-circle ml-1 has-black-border"
                                        onClick={() => handleStepChange(1)}
                                        >
                                        <span className="icon is-large">
                                            <i className="fas fa-arrow-right"></i>
                                        </span>
                                    </button>
                                </div>
                                </>                                
                            )}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default DSSFooter;